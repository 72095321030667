import { useId, type Dispatch, type SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';

import type { FilterKind, UserLocation } from '@/store/slices/prefs/userPreferencesSlice.ts';
import type { CubeInstanceUI } from '@/store/slices/ui/uiSlice.ts';
import { AutoCompleteCombo } from '@/components/common/bootstrap/AutoCompleteCombo.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { ToggleButton } from '@/components/common/bootstrap/ToggleButton.tsx';
import type { DataPrefs } from '@/components/prefs/UserPrefs.tsx';
import type { IntlMessages } from '@/types/intl';
import { isProdEnv } from '@/utils/config/configUtils.ts';
import { getSgwtHelpCenter } from '@/utils/sgwt/help-center.ts';

interface DataTabProps {
  dataPrefs: DataPrefs;
  setDataPrefs: Dispatch<SetStateAction<DataPrefs>>;
}
const userLocations: UserLocation[] = ['AUTOMATIC', 'EUROPE', 'ASIA', 'AMERICA'];

export function DataTab({ dataPrefs, setDataPrefs }: DataTabProps) {
  function openHelpCenter() {
    const topicId = isProdEnv() ? 'top.qc4fdru3' : 'top.nfaa5hl2';
    const widget = getSgwtHelpCenter();
    widget.topic(topicId);
  }

  function userPrefSetter<T extends keyof DataPrefs, U extends DataPrefs[T]>(
    prop: T,
  ): (value: U) => void {
    return value => {
      setDataPrefs({
        ...dataPrefs,
        [prop]: value,
      });
    };
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="d-flex align-items-center">
        <ToggleButton<FilterKind>
          formatId={'UserPrefs.Section.filter'}
          activeValue={dataPrefs.filterKind}
          values={['IMPACTING', 'NON_IMPACTING']}
          onClick={userPrefSetter('filterKind')}
        />

        <Button
          flat
          icon
          className="d-flex flex-center"
          aria-label="toggle menu"
          type="button"
          onClick={openHelpCenter}
        >
          <em className="icon icon-md" aria-hidden="true">
            help
          </em>
        </Button>
      </div>
      <div>
        <label htmlFor="userLocation" className="form-label">
          <FormattedMessage id="UserPrefs.UserLocation" />
        </label>
        <AutoCompleteCombo<UserLocation>
          id="userLocation"
          initialItems={userLocations}
          onChange={userPrefSetter('userLocation')}
          selectedItem={
            userLocations.find(location => dataPrefs.userLocation === location) ?? 'AUTOMATIC'
          }
        />
      </div>
      <div>
        <h3 className="py-2">Custom labels</h3>
        <Input
          label="Hierarchy.DeskCustomAxis1"
          value={dataPrefs.customHierarchiesLabels.DeskCustomAxis1 ?? ''}
          onChange={val =>
            userPrefSetter('customHierarchiesLabels')({
              ...dataPrefs.customHierarchiesLabels,
              DeskCustomAxis1: val,
            })
          }
        />
        <Input
          label="Hierarchy.DeskCustomAxis2"
          value={dataPrefs.customHierarchiesLabels.DeskCustomAxis2 ?? ''}
          onChange={val =>
            userPrefSetter('customHierarchiesLabels')({
              ...dataPrefs.customHierarchiesLabels,
              DeskCustomAxis2: val,
            })
          }
        />
        <Input
          label="Hierarchy.DeskCustomAxis3"
          value={dataPrefs.customHierarchiesLabels.DeskCustomAxis3 ?? ''}
          onChange={val =>
            userPrefSetter('customHierarchiesLabels')({
              ...dataPrefs.customHierarchiesLabels,
              DeskCustomAxis3: val,
            })
          }
        />
        <Input
          label="Hierarchy.DeskCustomAxis4"
          value={dataPrefs.customHierarchiesLabels.DeskCustomAxis4 ?? ''}
          onChange={val =>
            userPrefSetter('customHierarchiesLabels')({
              ...dataPrefs.customHierarchiesLabels,
              DeskCustomAxis4: val,
            })
          }
        />
        <Input
          label="Hierarchy.DeskCustomAxis5"
          value={dataPrefs.customHierarchiesLabels.DeskCustomAxis5 ?? ''}
          onChange={val =>
            userPrefSetter('customHierarchiesLabels')({
              ...dataPrefs.customHierarchiesLabels,
              DeskCustomAxis5: val,
            })
          }
        />
      </div>
    </div>
  );
}

interface InputProps {
  label: IntlMessages;
  value: string;
  onChange: (value: string) => void;
}

function Input({ label, value, onChange }: InputProps) {
  const id = label + useId();
  return (
    <div className="form-group-deprecated">
      <label htmlFor={id}>
        <FormattedMessage id={label} />
      </label>
      <input
        className="form-control"
        type="text"
        id={id}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
}
