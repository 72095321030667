import { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { nanoid } from 'nanoid';

import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import type { IntlMessages } from '@/types/intl';
import { objectKeys } from '@/utils/libs/entries.ts';
import type { StrictExclude } from '@/utils/libs/strictTypes.ts';

export interface EditTableModalProps {
  titleId?: IntlMessages;
  data: Record<string, string>;
  headers: [string, string];
}

export interface EditTableModalResult extends Record<string, string> {}

export type TableRowData = { id: string; key: string; value: string };

function initTableData(tableData: Record<string, string>): TableRowData[] {
  return objectKeys(tableData).map((key, index) => ({
    id: index.toString(),
    key,
    value: tableData[key],
  }));
}
export const EditTableModal = NiceModal.create(function EditTableModal(props: EditTableModalProps) {
  const { data, titleId, headers } = props;

  const modal = useModal();

  const [tableData, setTableData] = useState<TableRowData[]>(initTableData(data));

  function handleConfirm() {
    const newData: Record<string, string> = Object.fromEntries(
      tableData.filter(data => data.key !== '').map(data => [data.key, data.value]),
    );
    modal.resolve(newData);
  }

  function onAddRow() {
    return setTableData(prevValue => [...prevValue, { id: nanoid(9), key: '', value: '' }]);
  }

  function onChange(
    id: TableRowData['id'],
    entryKey: StrictExclude<keyof TableRowData, 'id'>,
    newValue: TableRowData['value'],
  ) {
    setTableData(prevState => {
      return prevState.map(element => {
        if (element.id !== id) {
          return element;
        }
        return {
          ...element,
          [entryKey]: newValue,
        };
      });
    });
  }

  function onDelete(id: string) {
    setTableData(tableData.filter(element => element.id !== id));
  }
  return (
    <BootstrapModal
      style={{ minHeight: '28em', minWidth: '51em' }}
      footer={<CancelConfirmFooter onConfirm={handleConfirm} />}
      titleId={titleId}
    >
      <div className="d-flex flex-column gap-4">
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th>{headers[0]}</th>
              <th>{headers[1]}</th>
              <th className="text-center p-0">
                <i className="icon icon-sm">delete_outline</i>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map(rowData => (
              <EditRowData
                key={rowData.id}
                onChange={onChange}
                rowData={rowData}
                onDelete={onDelete}
              />
            ))}
          </tbody>
        </table>
        <Button className="d-flex gap-3" onClick={onAddRow}>
          <i className="icon icon-sm">add</i>
          Add value
        </Button>
      </div>
    </BootstrapModal>
  );
});

type EditRowDataProps = {
  rowData: TableRowData;
  onDelete: (id: string) => void;
  onChange: (
    id: string,
    entryKey: StrictExclude<keyof TableRowData, 'id'>,
    newValue: string,
  ) => void;
};

function EditRowData(editRowDataProps: EditRowDataProps): JSX.Element | null {
  const { rowData, onChange, onDelete } = editRowDataProps;
  const { id, key, value } = rowData;
  return (
    <tr>
      <td className="d-flex p-0 justify-content-center align-items-center align-items-stretch">
        <input
          className="d-flex form-control w-100 h-100 align-self-stretch"
          onChange={event => onChange(id, 'key', event.target.value)}
          type="text"
          value={key}
        />
      </td>
      <td className="p-0">
        <input
          className="d-flex form-control"
          onChange={event => onChange(id, 'value', event.target.value)}
          type="text"
          value={value}
        />
      </td>
      <td width="45px" className="p-0">
        <Button
          className="d-flex btn btn-md btn-flat-secondary"
          flat
          variant="danger"
          onClick={() => onDelete(id)}
        >
          <i className="icon icon-sm">delete_outline</i>
        </Button>
      </td>
    </tr>
  );
}
