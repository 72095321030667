import { filterAvailableMeasures, removeFiltersOnNonExistingMeasures } from './mdx/utils.ts';

import type { Hierarchy } from '@/core/hierachies.ts';
import { mdxMeasuresStatement } from '@/core/query/mdx/clause/mdxMeasures.ts';
import { buildFilterStatement } from '@/core/query/mdx/filter/buildFilterStatement.ts';
import { getFromClause } from '@/core/query/mdx/widgets.ts';
import type { QueryParameters } from '@/store/selectors/QueryParameterSelector.ts';
import type { FilterKind } from '@/store/slices/prefs/userPreferencesSlice.ts';
import { objectKeys } from '@/utils/libs/entries.ts';

/**
 * @param hierarchyValues Hierarchies values ex: [AllMember/HierarchyValue1/HierarchyValue2]
 *  [] = total
 *  [AllMember] = First Level
 *  [AllMember/1D Japan] = Second Level
 * @param queryParameters Query parameters
 * @param filterKind
 */
export function getMdxForLevel(
  hierarchyValues: string[],
  queryParameters: QueryParameters,
  filterKind: FilterKind,
): string {
  /**
   * Niveau 0 = total
   *  hierarchyValues = []
   *  path = []
   *  currentLevel = 0
   *
   * Niveau 1 = ProfitCenter
   *  hierarchyValues = [AllMember]
   *  path = []
   *  currentLevel = 0
   *
   * Niveau 2 = ProfitCenter/GOP
   *  hierarchyValues = [AllMember/1D Japan]
   *  path = [1D Japan]
   *  currentLevel = 1
   */
  const {
    selectedHierarchies,
    selectedMeasureIds,
    cubeMode,
    valueDate,
    cubeName,
    perimeters,
    filters,
    exclusionMode,
  } = queryParameters;

  // Sanity checks
  const measures = filterAvailableMeasures(selectedMeasureIds, cubeMode);
  const queryFilters = removeFiltersOnNonExistingMeasures(filters, cubeMode);

  const isTotalQuery = hierarchyValues.length === 0;
  const currentLevel = Math.max(hierarchyValues.length - 1, 0);
  const currentHierarchy = selectedHierarchies[currentLevel];

  let rows = `[${currentHierarchy}].${isTotalQuery ? 'AllMember' : 'Children'}`;
  let subQuery = getFromClause(cubeName, perimeters);

  // we iterate in reverse order to nest queries in the right order
  for (let filterLevel = selectedHierarchies.length - 1; filterLevel >= 0; filterLevel--) {
    const filterHierarchy = selectedHierarchies[filterLevel];
    const measureFilters = queryFilters[filterHierarchy];

    if (measureFilters === undefined || objectKeys(measureFilters).length === 0) {
      continue;
    }

    if (filterLevel < currentLevel) {
      // case ABOVE => No filter
      continue;
    }

    if (filterLevel === currentLevel && !isTotalQuery) {
      // case EQUAL => filter in row clause
      rows = buildFilterStatement(filterHierarchy, cubeMode, measureFilters);
      continue;
    }

    if (filterKind === 'IMPACTING') {
      // case BELOW => filter in sub-query IF filters are set as "impacting" otherwise, no filter.
      const filterStatement = buildFilterStatement(filterHierarchy, cubeMode, measureFilters);
      subQuery = `SELECT ${filterStatement} ON COLUMNS
                  FROM (${subQuery})
                  WHERE {
                      ( ${mdxWhereClause(valueDate)}
                      )
                      }`;
    }
  }

  return `SELECT NON EMPTY { ${mdxMeasuresStatement(measures, cubeMode)} }
          ON COLUMNS,
              NON EMPTY ${rows} ON ROWS
          FROM
              (
              ${subQuery}
              )
          WHERE {
              ( ${mdxWhereClause(valueDate, selectedHierarchies, hierarchyValues)}
              ) }`;
}

function mdxWhereClause(
  valueDate: string,
  selectedHierarchies: Hierarchy[] = [],
  hierarchyValues: string[] = [],
) {
  const previousLevels = hierarchyValues
    .slice(1) // First hierarchyValue is AllMember
    .map((hierarchyValue, levelIndex) => {
      const hierarchy = selectedHierarchies[levelIndex];
      return `[${hierarchy}].[${hierarchy}].[${hierarchyValue}]`;
    });

  return [`[ValueDate].[${valueDate}]`, '[RiskEpoch].[LAST]'].concat(previousLevels).join(',');
}

export function getMdxForPricingDateTime(
  cubeName: string,
  perimeters: string[],
  valueDate: string,
) {
  return `
          SELECT NON EMPTY [PricingDateTime].AllMembers ON ROWS, 
              {[Measures].[contributors.COUNT]} ON COLUMNS
          FROM
              ( ${getFromClause(cubeName, perimeters)} )
          WHERE
              {([ValueDate].[${valueDate}], [RiskEpoch].[LAST] , [IsExcluded].[FALSE] )}`;
}
