import type { CellClassRules } from '@ag-grid-community/core';

import type { AllMeasureId } from '@/core/measures.ts';
import type { RowData } from '@/core/parsing/parseResponse.ts';
import {
  getCoefficientForMeasure,
  selectColumnHasColor,
} from '@/store/slices/colorRules/colorsRulesSlice.ts';
import type { WidgetMeasureColumn } from '@/store/slices/widget/widgetTypes.ts';
import { store } from '@/store/store.ts';
import { getValueForMeasure } from '@/components/equityRisk/gridMappings/ColumnMapping.ts';
import type { FetchContext } from '@/components/equityRisk/gridMappings/fetchContext.ts';
import type { MeasureColumn } from '@/components/equityRisk/gridMappings/measureColumns.ts';
import { allColorsOfRules } from '@/components/prefs/tabs/ColorRules/colors.ts';

export function getColorRulesCellClassRules(
  measureColumn: MeasureColumn | WidgetMeasureColumn,
  context: FetchContext,
): CellClassRules<RowData> {
  return Object.fromEntries(
    allColorsOfRules.map(color => [
      color.cssClass,
      ({ data }) => {
        function getMeasureValue(measure: AllMeasureId) {
          return getValueForMeasure(data, measure, context) * getCoefficientForMeasure(measure);
        }

        return selectColumnHasColor(
          store.getState(),
          measureColumn.measureId,
          color,
          getMeasureValue,
          getMeasureValue(measureColumn.measureId),
        );
      },
    ]),
  );
}
