import { assertUnreachable } from '@sgme/fp';

import type { UserRole } from '@/store/slices/user/userSlice.ts';
import { webApi } from '@/web/utils/webApi.ts';
import { logger } from '@/utils/libs/logger.ts';

export async function getWebsocketUrl(
  userRole: UserRole,
  userLocation: string,
): Promise<string | undefined> {
  const role = frontRoleToBackRoleMapper(userRole);
  const place = userLocation === 'AUTOMATIC' ? getDefaultPlace() : userLocation;

  try {
    const { websocketUrl } = await webApi
      .get(`api/greatRest/wsUrl?place=${place}&activity=${role}`)
      .json<{ websocketUrl: string }>();
    return websocketUrl;
  } catch (error) {
    logger.logError('Error while getting wsUrl from great {error_s}', JSON.stringify(error));
    return undefined;
  }
}

function getDefaultPlace() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone.split('/')[0]?.toUpperCase();
}

type BackendUserRole = 'SUPPORT' | 'MGT' | 'DELTA1' | 'EXO' | 'DLP' | 'FSI' | 'READ_ONLY';

export function frontRoleToBackRoleMapper(userRole: UserRole): BackendUserRole {
  switch (userRole) {
    case 'Devci':
    case 'Support':
      return 'SUPPORT';
    case 'Management':
      return 'MGT';
    case 'TraderIDX':
      return 'DELTA1';
    case 'TraderExo':
      return 'EXO';
    case 'TraderDLP':
      return 'DLP';
    case 'TraderFSI':
      return 'FSI';
    case 'HedgerAmer':
    case 'ReadOnly':
      return 'READ_ONLY';
    default:
      assertUnreachable(userRole, 'User role not handled');
  }
}
