import { useCallback, useRef, type CSSProperties, type PropsWithChildren } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { useIntl } from 'react-intl';

import { useClickAway } from '@/components/common/hooks/useClickAway.ts';
import { useKey } from '@/components/common/hooks/useKey.ts';
import type { IntlMessages } from '@/types/intl';

function ModalHeader(props: {
  titleId: IntlMessages;
  onClick: () => void;
  values?: Record<string, string | number>;
}) {
  const { formatMessage } = useIntl();
  return (
    <div className="modal-header">
      <h3 className="modal-title">{formatMessage({ id: props.titleId }, props.values)}</h3>
      <button
        type="button"
        className="btn-close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={props.onClick}
      />
    </div>
  );
}

function ModalFooter(props: PropsWithChildren<{}>): JSX.Element {
  return <div className="modal-footer">{props.children}</div>;
}

export type ModalProps = PropsWithChildren<{
  titleId?: IntlMessages;
  titleValues?: Record<string, string | number>;
  footer?: JSX.Element;
  isFullScreen?: boolean;
  style?: CSSProperties;
  size?: 'sm' | 'md' | 'lg';
  closeOnClickAway?: boolean;
}>;

export function BootstrapModal({
  titleId = 'Modal.Title' as IntlMessages,
  isFullScreen,
  titleValues,
  children,
  footer,
  style,
  size = 'md',
  closeOnClickAway = true,
}: ModalProps) {
  const modal = useModal();
  const ref = useRef(null);
  const closeModal = useCallback(() => {
    modal.remove();
  }, [modal]);

  useKey('Escape', closeModal);
  useClickAway(ref, () => {
    if (closeOnClickAway) {
      closeModal();
    }
  });

  return (
    <div>
      <div
        className={`modal ${modal.visible ? 'd-block' : ''} modal-${size} ${isFullScreen ? 'modal-fullscreen' : ''}`}
        tabIndex={-1}
        role="dialog"
      >
        <div
          className="modal-dialog d-flex justify-content-center"
          role="document"
          ref={ref}
          style={{ ...style }}
        >
          <div className="modal-content " style={{ minHeight: '23em', ...style }}>
            <ModalHeader values={titleValues} titleId={titleId} onClick={closeModal} />

            <div className="modal-body">{children}</div>

            {footer && <ModalFooter>{footer}</ModalFooter>}
          </div>
        </div>
      </div>
      <div className={`modal-backdrop fade ${modal.visible ? 'show' : ''}`} />
    </div>
  );
}
