import { useMemo, useState } from 'react';
import type { CubeMode } from '../../../../scripts/AppConfig';
import type { ColDef } from '@ag-grid-community/core';

import { getCellValueFormatter } from '@/core/format.ts';
import { getMeasureType, type MeasureType } from '@/core/measures.ts';
import type { MdxColumn } from '@/core/parsing/parseResponse.ts';
import { useAppSelector } from '@/store/hooks.ts';
import {
  isWidgetDefMonoAxis,
  isWidgetMeasureColumn,
  type WidgetColumn,
  type WidgetColumnMultiAxis,
  type WidgetDef,
  type WidgetHierarchyColumn,
} from '@/store/slices/widget/widgetTypes.ts';
import { useMeasureFormat } from '@/components/common/hooks/useMeasureFormat.tsx';
import { makeWidgetMeasureKeyGetter } from '@/components/equityRisk/gridMappings/ColumnMapping.ts';
import { useFetchContext } from '@/components/equityRisk/gridMappings/fetchContext.ts';
import { getColorRulesCellClassRules } from '@/components/equityRisk/gridMappings/getColorRulesCellClassRules.ts';
import {
  getMeasureWidgetName,
  type WidgetParams,
} from '@/components/equityRisk/widgets/allWidgets.ts';

type UseWidgetColDefs = [ColDef[], { updateColumns: (columns: MdxColumn[]) => void }];

export function useWidgetColDefs(
  widgetDef: WidgetDef<WidgetParams>,
  cubeMode: CubeMode,
): UseWidgetColDefs {
  const [mdxColumns, setMdxColumns] = useState<MdxColumn[]>([]);
  const negativeNumberFormat = useAppSelector(state => state.userPreferences.negativeNumberFormat);
  const numberFormat = useAppSelector(state => state.userPreferences.numberFormat);
  const measureFormat = useMeasureFormat();
  const context = useFetchContext(makeWidgetMeasureKeyGetter, false);

  const colDefs = useMemo(() => {
    const widgetColumns = isWidgetDefMonoAxis(widgetDef)
      ? widgetDef.columns
      : getDynamicWidgetColumns(mdxColumns, widgetDef.params);

    return widgetColumns?.map((widgetColumn: WidgetColumn) => {
      const colId =
        widgetColumn.type === 'measure'
          ? getMeasureWidgetName(widgetColumn.measureId, cubeMode)
          : widgetColumn.name;

      const measureType = getWidgetMeasureType(widgetColumn, widgetDef.params);

      const headerName =
        widgetColumn.type === 'measureCrossHierarchy'
          ? getMeasureCrossHierarchyName(widgetColumn.name)
          : measureFormat(widgetColumn.type === 'hierarchy' ? 'Hierarchy' : widgetColumn.measureId);

      const colDef: ColDef = {
        colId,
        headerName,
        valueGetter: params => params.data?.[colId],
        valueFormatter: getCellValueFormatter(numberFormat, negativeNumberFormat, measureType),
        cellClass: measureType === 'quantity' ? 'fst-italic' : undefined,
        cellClassRules: isWidgetMeasureColumn(widgetColumn)
          ? getColorRulesCellClassRules(widgetColumn, context)
          : undefined,
        type:
          widgetColumn.type === 'measure' || widgetColumn.type === 'measureCrossHierarchy'
            ? 'rightAligned'
            : undefined,
      } satisfies ColDef;

      return colDef;
    });
  }, [widgetDef, mdxColumns, cubeMode, measureFormat, numberFormat, negativeNumberFormat, context]);

  return [colDefs, { updateColumns: setMdxColumns }];
}

function getWidgetMeasureType(column: WidgetColumn, widgetParams: WidgetParams): MeasureType {
  if (column.type === 'measureCrossHierarchy') {
    if (widgetParams.metric === undefined) {
      return 'text';
    }
    return getMeasureType(widgetParams.metric.value);
  } else if (column.type === 'measure') {
    return getMeasureType(column.measureId);
  } else {
    return 'text';
  }
}

function getMeasureCrossHierarchyName(name: string): string {
  return name.split('/')[1];
}

function getDynamicWidgetColumns(
  mdxColumns: MdxColumn[],
  widgetParams: WidgetParams,
): WidgetColumnMultiAxis[] {
  const { metric, rowAggregation, columnAggregation } = widgetParams;
  if (metric === undefined || rowAggregation === undefined || columnAggregation === undefined) {
    return [];
  }

  return mdxColumns.map(({ path, type }) => {
    const name = path.join('/');
    if (type === 'hierarchy') {
      return { name, type: 'hierarchy' } as WidgetHierarchyColumn;
    }
    return {
      type: 'measureCrossHierarchy',
      name,
      measureId: metric.value,
      rowHierarchy: rowAggregation.value,
      columnHierarchy: columnAggregation.value,
    };
  });
}
