import { getPathFromQueryId } from '@/core/webSocket/queryId.ts';
import type { WsEventEmitter } from '@/core/webSocket/types.ts';
import { createWebsocketConnection } from '@/core/webSocket/WebSocket.ts';
import {
  perimeterPresetsSlice,
  selectActivePerimeterPreset,
} from '@/store/slices/prefs/perimeterPresetsSlice.ts';
import { querySlice } from '@/store/slices/query/querySlice.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import {
  fetchAnalyticalStructure,
  fetchCurrencyCache,
  fetchProfitCenters,
} from '@/store/slices/queryCache/queryCacheThunks.ts';
import { store, type AppThunk } from '@/store/store.ts';
import { registerTrafficWatcher } from '@/components/equityRisk/traffic/registerTrafficWatcher.tsx';
import { getAllowedActivePerimeters } from '@/components/gridTable/tools/reducePerimeterKeys.ts';
import { createError } from '@/utils/error/createError.ts';
import { perfLogger } from '@/utils/libs/perfLogger.ts';

export async function equityRiskLoader() {
  const wsUrl = store.getState().query.wsUrl;
  if (wsUrl === undefined) {
    throw createError('Error.GreatServerNoUrl');
  }

  const dispatch = store.dispatch;
  const webSocketConnection = await createWebsocketConnection(wsUrl);
  await Promise.all([
    dispatch(fetchCurrencyCache(webSocketConnection)),
    dispatch(fetchAnalyticalStructure(webSocketConnection)),
    dispatch(fetchProfitCenters(webSocketConnection)),
  ]);
  dispatch(applyPresetThunk());
  registerPerfLogger(webSocketConnection.wsEventEmitter);
  registerTrafficWatcher(webSocketConnection.wsEventEmitter);

  return { webSocketConnection };
}

function registerPerfLogger(wsEventEmitter: WsEventEmitter) {
  perfLogger.startTimer();
  wsEventEmitter.on('cellSetData', ({ queryId }) => {
    const path = getPathFromQueryId(queryId);
    if (path === '') {
      perfLogger.time('rootQueryResponse');
    }
    perfLogger.createHistogram(queryId);
  });
  wsEventEmitter.on('cellData', ({ queryId }) => {
    perfLogger.recordHistogramTime(queryId);
  });
  wsEventEmitter.on('end', ({ queryId }) => {
    perfLogger.destroyHistogram(queryId);
  });
}

export function applyPresetThunk(): AppThunk {
  return (dispatch, getState) => {
    const appState = getState();
    const allPerimeters = queryCacheSlice.selectors.perimeterKeys(appState);
    const activePerimeterPreset = selectActivePerimeterPreset(appState);
    const allowedPerimeters = getAllowedActivePerimeters(
      activePerimeterPreset?.perimeters ?? [],
      allPerimeters,
    );
    dispatch(querySlice.actions.setPerimeters(allowedPerimeters));
    dispatch(
      perimeterPresetsSlice.actions.setDraftPerimeterPreset(activePerimeterPreset!.perimeters!),
    );
  };
}
