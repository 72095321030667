import { useMemo } from 'react';
import type { CubeMode } from '../../../../scripts/AppConfig';
import { assertUnreachable } from '@sgme/fp';

import type { AllMeasureId, Measure, MeasureNameFromCache } from '@/core/measures.ts';
import { useAppSelector } from '@/store/hooks.ts';
import type {
  NegativeNumberFormat,
  NumberFormat,
} from '@/store/slices/prefs/userPreferencesSlice.ts';
import { selectCurrencyGetter } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { store } from '@/store/store.ts';
import { useMeasureFormat } from '@/components/common/hooks/useMeasureFormat.tsx';
import {
  makeMeasureKeyGetter,
  makeWidgetMeasureKeyGetter,
} from '@/components/equityRisk/gridMappings/ColumnMapping.ts';

export interface FetchContext {
  getCubeMode: () => CubeMode;
  getMeasureKey: (measure: Measure, cubeMode: CubeMode) => string;
  getFromCache: (measureId: MeasureNameFromCache, dataPath: string[]) => any;
  getLabel: (measureId: AllMeasureId) => string;
  numberFormat: NumberFormat;
  negativeNumberFormat: NegativeNumberFormat;
  flashCell: boolean;
}

export function useFetchContext(
  makeGetMeasureKey: typeof makeWidgetMeasureKeyGetter | typeof makeMeasureKeyGetter,
  flashCellParam: boolean = true,
): FetchContext {
  const numberFormat = useAppSelector(state => state.userPreferences.numberFormat);
  const measureFormat = useMeasureFormat();
  const negativeNumberFormat = useAppSelector(state => state.userPreferences.negativeNumberFormat);
  const currencyGetter = useAppSelector(selectCurrencyGetter);
  const flashCell = useAppSelector(state => state.userPreferences.flashCell);

  return useMemo(() => {
    return {
      numberFormat,
      flashCell: flashCell && flashCellParam,
      getCubeMode: () => store.getState().query.cubeMode,
      negativeNumberFormat,
      getMeasureKey: makeGetMeasureKey(),
      getFromCache: (measure, dataPath) => {
        switch (measure) {
          case 'Currency':
            return currencyGetter(dataPath.slice(1));
          default:
            assertUnreachable(measure, 'Should handle all cache measures');
        }
      },
      getLabel: measureFormat,
    };
  }, [
    numberFormat,
    flashCell,
    flashCellParam,
    negativeNumberFormat,
    makeGetMeasureKey,
    measureFormat,
    currencyGetter,
  ]);
}
