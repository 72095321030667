import type { GridApi, SortModelItem } from '@ag-grid-community/core';
import { assertUnreachable } from '@sgme/fp';

import type { MeasureType } from '@/core/measures.ts';
import type { RowData } from '@/core/parsing/parseResponse.ts';
import type { ColumnContext } from '@/components/equityRisk/gridMappings/measureColumns.ts';

export function absValueComparator(valueA: number, valueB: number) {
  return absNaN(valueA) - absNaN(valueB);
}

function absNaN(value: number) {
  if (isNaN(value)) {
    return 0;
  }
  return Math.abs(value);
}

export function getMeasureComparator(
  measureType: MeasureType,
): (valueA: any, valueB: any) => number {
  switch (measureType) {
    case 'percentage':
    case 'currency':
    case 'price':
    case 'quantity':
      return absValueComparator;
    case 'text':
      return (a, b) => a?.toString()?.localeCompare(b?.toString());
    default:
      assertUnreachable(measureType, '');
  }
}

export function getRowDataComparator(
  api: GridApi,
  sortModel: SortModelItem[],
): (a: RowData, b: RowData) => number {
  return (a, b) => {
    for (const sortModelItem of sortModel) {
      const column = api.getColumn(sortModelItem.colId);
      const colDef = column?.getColDef();
      if (colDef === undefined) {
        continue;
      }

      const context: ColumnContext = colDef.context;
      const getValue = context.getValue;
      const valA = getValue(a);
      const valB = getValue(b);

      const measureComparator = getMeasureComparator(context.measureType);
      const compareResult = measureComparator(valA, valB);

      if (compareResult === 0) {
        continue;
      }
      if (sortModelItem.sort === 'asc') {
        return compareResult;
      }

      return -compareResult;
    }
    return 0;
  };
}
