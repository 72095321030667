import type { HedgerExclusionMode } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { ExclusionMode } from '@/store/slices/prefs/visualizationPresetSchema.ts';

// Hedger do not use for now the same ExclusionMode as Great
export function getHedgerExclusionMode(exclusionMode: ExclusionMode): HedgerExclusionMode {
  switch (exclusionMode) {
    case 'ALL':
      return 'NOT_APPLIED';
    case 'EXCLUDED':
      return 'ONLY_EXCLUDED_DATA';
    case 'NOTEXCLUDED':
      return 'APPLIED';
  }
}
