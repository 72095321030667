import { createFileRoute } from '@tanstack/react-router';

import { adjustmentApi } from '@/store/api/adjustmentsApi/adjustmentApi.ts';
import { store } from '@/store/store.ts';
import { analyticalStructureLoader } from '@/web/router/analyticalStructureLoader.ts';
import { Adjustments } from '@/components/adjustments/Adjustments.tsx';

export const Route = createFileRoute('/adjustments/')({
  component: Adjustments,

  loader: async ({ params }) => {
    await analyticalStructureLoader();
    const request = await store.dispatch(adjustmentApi.endpoints.loadBlotterDeals.initiate());
    if (request.error === undefined) {
      return {
        preTradeDeals: request.data,
      };
    }
    return { preTradeDeals: [] };
  },
});
