import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useRouter } from '@tanstack/react-router';

import { usePostOrderGroupsMutation, type GreatSource } from '@/store/api/hedgerApi/hedgerApi.ts';
import { useGetScenariosQuery, type Scenario } from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { selectActivePerimeterPreset } from '@/store/slices/prefs/perimeterPresetsSlice.ts';
import { presetKeyToString } from '@/store/slices/prefs/presetKey.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { selectUserHasAnyPermission } from '@/store/slices/user/userSlice.ts';
import { Dropdown } from '@/components/common/bootstrap/Dropdown.tsx';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import { getHedgerExclusionMode } from '@/components/hedger/execution/common/getHedgerExclusionMode.tsx';
import { getUrl } from '@/utils/libs/getUrl.ts';

const hedgerDropdownTitle = { name: 'Hedge execution' };

export function HedgeExecutionDropdownButton(): JSX.Element | null {
  const { trackEvent } = useMatomo();
  const { navigate } = useRouter();
  const scenariosQueryResult = useGetScenariosQuery();
  const [postOrderGroup, { isLoading }] = usePostOrderGroupsMutation({
    fixedCacheKey: 'current-order-group',
  });
  const dispatch = useAppDispatch();
  const greatSource = useGetGreatSource();
  const canAccessStorm = useAppSelector(selectUserHasAnyPermission('useHedgerSourceGreat'));
  if (!canAccessStorm) {
    return null;
  }
  if (scenariosQueryResult.isError) {
    return <div>Error</div>;
  }
  if (isLoading) {
    return (
      <div className="d-flex justify-content-around align-items-center">
        <div className="spinner spinner-md" role="status" />
      </div>
    );
  }
  const scenarios = scenariosQueryResult.data?.scenarios ?? [];

  async function handleClickAsync({ scenarioId }: Scenario) {
    trackEvent({ category: 'Hedger', action: 'Open scenario modal' });
    await postOrderGroupAsync(scenarioId);
  }

  async function postOrderGroupAsync(scenarioId: number) {
    if (greatSource === undefined) {
      dispatch(
        addErrorToastThunk(
          'Your Great Server websocket URL is incorrect, or you may not have access to any cube',
        ),
      );
      return;
    }

    try {
      const orderGroup = await postOrderGroup({ source: greatSource, scenarioId }).unwrap();
      if (orderGroup !== undefined) {
        await navigate({
          to: '/hedger/order/$orderGroupId',
          params: { orderGroupId: orderGroup.orderGroupId.toString() },
        });
      }
    } catch (error: unknown) {
      handleHedgerError('Error while generating order group', error);
    }
  }

  return (
    <Dropdown<Scenario>
      variant="secondary"
      className="btn btn-discreet-primary dropdown-toggle"
      itemsAsObjects={scenarios}
      itemToString={item => item.name}
      selectedItem={hedgerDropdownTitle as Scenario}
      align="right"
      disabled={
        scenariosQueryResult.isLoading ||
        scenariosQueryResult.isError ||
        scenariosQueryResult.isUninitialized
      }
      onItemClick={handleClickAsync}
    />
  );
}

function useGetGreatSource(): GreatSource | undefined {
  const activePerimeterPreset = useAppSelector(selectActivePerimeterPreset);
  const activePerimeterPresetKey = useAppSelector(state => state.perimeterPresets.activePresetKey);
  const { wsUrl, exclusionMode } = useAppSelector(state => state.query);

  const greatInstance = getUrl(wsUrl)?.host;
  const defaultPerimeters = useAppSelector(queryCacheSlice.selectors.profitCenters);

  const selectedPerimeterList = activePerimeterPreset?.perimeters ?? defaultPerimeters ?? [];

  if (greatInstance === undefined) {
    return undefined;
  }

  return {
    sourceType: 'GREAT',
    greatDetails: {
      greatInstance,
      selectedPerimeterList,
      perimeterPresetName: presetKeyToString(activePerimeterPresetKey),
      exclusionMode: getHedgerExclusionMode(exclusionMode),
    },
  };
}
