import type { CubeMode } from '../../../../scripts/AppConfig';
import type { ITooltipParams } from '@ag-grid-community/core';

import {
  getMeasureById,
  isFromCache,
  type AllMeasureId,
  type MeasureType,
} from '@/core/measures.ts';
import type { RowData } from '@/core/parsing/parseResponse.ts';
import { getValueForMeasure } from '@/components/equityRisk/gridMappings/ColumnMapping.ts';
import type { FetchContext } from '@/components/equityRisk/gridMappings/fetchContext.ts';
import { isDevEnv } from '@/utils/config/configUtils.ts';

export interface MeasureColumn {
  measureId: AllMeasureId;
  parent?: AllMeasureId;
  sticky?: boolean;
  tooltip?: (params: ITooltipParams<RowData>, context: FetchContext) => string | undefined;
}

export const defaultMeasureColumns: MeasureColumn[] = [
  {
    measureId: 'Currency',
  },

  {
    measureId: 'UnderlyerSpotRT',
    tooltip: (params, context) => {
      const spotSourceValue = getValueForMeasure(params.data, 'SpotSource', context);
      if (spotSourceValue === undefined || isGoldenSource(spotSourceValue)) {
        return undefined;
      }
      return `Source : ${spotSourceValue}`;
    },
  },
  {
    measureId: 'UnderlyerSpotRisk',
  },
  {
    measureId: 'UnderlyerSpotRTMove',
  },
  // DELTA
  {
    measureId: 'DeltaCurrencyIntraday',
  },
  {
    measureId: 'DeltaOpenRTIntraday',
    parent: 'DeltaCurrencyIntraday',
    sticky: true,
  },
  {
    measureId: 'NDeltaOpenMove',
    parent: 'DeltaCurrencyIntraday',
  },
  {
    measureId: 'XGamma',
    parent: 'DeltaCurrencyIntraday',
  },
  {
    measureId: 'XGammaDiag',
    parent: 'DeltaCurrencyIntraday',
  },
  {
    measureId: 'DeltaJumpOpenCurrency',
    parent: 'DeltaCurrencyIntraday',
  },
  {
    measureId: 'DeltaJumpOpen',
    parent: 'DeltaCurrencyIntraday',
  },
  {
    measureId: 'NDeltaOpenMoveRTDisplayCurrency',
    parent: 'DeltaCurrencyIntraday',
  },
  {
    measureId: 'CrossGammaCrossOpenRTDisplayCurrency',
    parent: 'DeltaCurrencyIntraday',
  },
  {
    measureId: 'CrossGammaDiagOpenRTDisplayCurrency',
    parent: 'DeltaCurrencyIntraday',
  },
  {
    measureId: 'DeltaExcludingFixingOpenRTDisplayCurrency',
    parent: 'DeltaCurrencyIntraday',
  },
  {
    measureId: 'DeltaOpenIntraday',
    parent: 'DeltaCurrencyIntraday',
  },

  {
    measureId: 'Spot0',
  },
  {
    measureId: 'DeltaCurrencyOfficial',
  },
  {
    measureId: 'DeltaOpenOfficial',
    parent: `DeltaCurrencyOfficial`,
  },

  {
    measureId: 'DeltaDailyCurrency',
  },
  {
    measureId: 'DeltaDaily',
    parent: 'DeltaDailyCurrency',
  },

  {
    measureId: 'DeltaDraftDailyCurrency',
    parent: 'DeltaDailyCurrency',
  },

  {
    measureId: 'Gamma',
  },
  {
    measureId: 'GammaDaily',
    parent: 'Gamma',
  },
  {
    measureId: 'GammaOpen',
    parent: 'Gamma',
  },

  {
    measureId: 'Vega',
  },
  {
    measureId: 'VegaDaily',
    parent: 'Vega',
  },
  {
    measureId: 'VegaOpen',
    parent: 'Vega',
  },

  {
    measureId: 'Theta',
  },
  {
    measureId: 'ThetaDaily',
    parent: 'Theta',
  },
  {
    measureId: 'ThetaOpen',
    parent: 'Theta',
  },

  {
    measureId: 'PnlTotalE',
  },
  {
    measureId: 'Pnl0E',
    parent: 'PnlTotalE',
  },
  {
    measureId: 'PnlThetaE',
    parent: 'PnlTotalE',
  },
  {
    measureId: 'PnlGammaE',
    parent: 'PnlTotalE',
  },
  {
    measureId: 'PnlDeltaDayE',
    parent: 'PnlTotalE',
  },

  {
    measureId: 'SpotSource',
  },
  {
    measureId: 'SpotUpdate',
  },
  {
    measureId: 'CrossGammaTransDisplayCurrency',
  },
  {
    measureId: 'CrossGammaTransOpenDisplayCurrency',
    parent: 'CrossGammaTransDisplayCurrency',
  },
  {
    measureId: 'CrossGammaTransDailyDisplayCurrency',
    parent: 'CrossGammaTransDisplayCurrency',
  },
  {
    measureId: 'ProductQuantity',
  },
  {
    measureId: 'ProductQuantityOpen',
  },
  {
    measureId: 'ProductQuantityDaily',
  },
];

export function isGoldenSource(spotValue: string | undefined): boolean {
  if (isDevEnv()) {
    // The spotValue in devEnv is a number
    return spotValue + '' === '2';
  }
  return spotValue === 'NMP_FAIRVAL' || spotValue === 'ELEKTRON_DD';
}

export function isMeasureColumnAvailable(mc: MeasureColumn, cubeMode: CubeMode): boolean {
  if (isFromCache(mc.measureId)) {
    return true;
  }
  const measure = getMeasureById(mc.measureId);
  return cubeMode === 'sgCube' || measure.availableOnGreat;
}

export interface ColumnContext {
  getValue: (data: any) => any;
  measureType: MeasureType;
}
