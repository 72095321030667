import { useCallback, useRef, useState } from 'react';
import type { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useIntl } from 'react-intl';

import type { RowData } from '@/core/parsing/parseResponse.ts';
import type { WebSocketConnection } from '@/core/webSocket/types.ts';
import { useAppSelector } from '@/store/hooks.ts';
import {
  selectWidgetQueryParams,
  type WidgetConfigParams,
} from '@/store/slices/widget/widgetsSlice.ts';
import { type WidgetDef, type WidgetQueryParams } from '@/store/slices/widget/widgetTypes.ts';
import { store } from '@/store/store.ts';
import { storeEventEmitter } from '@/store/storeEventEmitter.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Dropdown } from '@/components/common/bootstrap/Dropdown.tsx';
import { MessageTooltip } from '@/components/common/bootstrap/MessageTooltip.tsx';
import { useEventEmitter } from '@/components/common/hooks/useEventEmitter.ts';
import { getRowId } from '@/components/equityRisk/dataSource/WebSocketDataSource.ts';
import { type WidgetParams } from '@/components/equityRisk/widgets/allWidgets.ts';
import { useWidgetColDefs } from '@/components/equityRisk/widgets/useWidgetColDefs.ts';
import { WidgetBadges } from '@/components/equityRisk/widgets/WidgetBadges.tsx';
import { WidgetDataSource } from '@/components/equityRisk/widgets/WidgetDataSource.ts';
import type { WidgetDropdownChoice } from '@/components/equityRisk/widgets/WidgetPanel.tsx';
import { objectKeys } from '@/utils/libs/entries.ts';

interface WidgetProps {
  webSocketConnection: WebSocketConnection;
  widgetDef: WidgetDef<WidgetParams>;
  onMenuClick: (widgetId: string, dropdownChoice: WidgetDropdownChoice) => void;
}

export function Widget(props: WidgetProps) {
  const widgetQueryParams = useAppSelector(selectWidgetQueryParams);

  const [gridApi, setGridApi] = useState<GridApi<RowData> | undefined>();
  const { webSocketConnection, onMenuClick, widgetDef } = props;
  const { formatMessage } = useIntl();
  const refDataSource = useRef<WidgetDataSource>();
  const { params, name, id } = widgetDef;
  const [colDefs, { updateColumns }] = useWidgetColDefs(widgetDef, widgetQueryParams.cubeMode);

  const configParamsUpdateListener = useCallback<
    (params: Record<string, WidgetConfigParams>) => void
  >(
    paramsById => {
      const updatedParams = paramsById[widgetDef.id];
      if (updatedParams === undefined) {
        return;
      }
      refDataSource.current?.refreshQuery(widgetDef.getQuery(widgetQueryParams, updatedParams));
    },
    [widgetDef, widgetQueryParams],
  );

  const queryParamsUpdateListener = useCallback<(params: WidgetQueryParams) => void>(
    updatedWidgetQueryParams => {
      refDataSource.current?.refreshQuery(
        widgetDef.getQuery(updatedWidgetQueryParams, widgetDef.params),
      );
    },
    [widgetDef],
  );

  useEventEmitter(storeEventEmitter, 'onWidgetConfigParamsUpdate', configParamsUpdateListener);
  useEventEmitter(storeEventEmitter, 'onWidgetQueryParamsUpdate', queryParamsUpdateListener);
  useEventEmitter(storeEventEmitter, 'colorChanged', () => gridApi?.refreshCells());

  function onGridReady({ api }: GridReadyEvent<RowData>) {
    const onDynamicColumnsReady = widgetDef.category === 'MultiAxis' ? updateColumns : undefined;

    const dataSource = new WidgetDataSource(
      webSocketConnection,
      api,
      widgetDef.getQuery(widgetQueryParams, widgetDef.params),
      id,
      store,
      onDynamicColumnsReady,
    );

    api.updateGridOptions({ serverSideDatasource: dataSource });
    refDataSource.current = dataSource;
    setGridApi(api);
  }

  function onItemClick(choice: WidgetDropdownChoice) {
    return onMenuClick(id, choice);
  }

  const menuPanelItems: WidgetDropdownChoice[] =
    objectKeys(params).length > 0 ? ['Edit', 'Remove'] : ['Remove'];

  return (
    <div className="h-100 w-100 d-flex flex-column p-2 gap-1">
      <div className="widget-draghandle cursor-grab">
        <div className="d-flex justify-content-between align-items-center">
          <h5>{name}</h5>
          <div className="d-flex">
            <MessageTooltip delay={300} placement="bottom" messageId="Widget.Buttons.Autosize">
              <Button flat className="btn-icon" onClick={() => gridApi?.autoSizeAllColumns()}>
                <i className="icon">compare_arrows</i>
              </Button>
            </MessageTooltip>

            <Dropdown<WidgetDropdownChoice>
              className="btn-icon"
              flat
              variant="primary"
              itemsAsObjects={menuPanelItems}
              itemToString={item => formatMessage({ id: `WidgetPanel.Dropdown.${item}` })}
              onItemClick={onItemClick}
            >
              <i className="icon">more_vert</i>
            </Dropdown>
          </div>
        </div>
      </div>

      <WidgetBadges params={params} />

      <AgGridReact<RowData>
        className="ag-theme-alpine ag-theme-era"
        modules={[ServerSideRowModelModule]}
        getRowId={getRowId}
        onGridReady={onGridReady}
        columnDefs={colDefs}
        rowModelType="serverSide"
        rowHeight={25}
        serverSideEnableClientSideSort
        suppressMenuHide={false}
        loading={colDefs.length === 0}
      />
    </div>
  );
}
