export interface BlotterDeal {
  productDescription: string;
  sgSide: string;
  portfolio: string;
  productQuantity: number;
  productPrice: number;
  amount: number;
  creationUser: string;
  xOneReference: string;
  status: string;
}
export const wayValues = ['Buy', 'Sell'] as const;
export type TradeWay = (typeof wayValues)[number];

export interface PreTradeDeal {
  tradeReference: string;
  instrumentName: string;
  instrumentContractName: string;
  action: string;
  tradeInfo: {
    trader: {
      rtfeLogin: string;
    };
    creationUser: {
      rtfeLogin: string;
    };
    portfolio: {
      name: string;
    };
    comment: string;
    tradeWay: TradeWay;
    tradeDate: string;
    tradeType: string;
    preTradeType: string;
    market: {
      name: string;
    };
  };
  productInfo: {
    productQuantity: number;
    productPrice: number;
    securityCompleteCode: {
      code: string;
      scheme: string;
    };
    amount: number;
  };
}

export interface PostResponse {
  requestId: string;
  connectorMessageStatus: string;
  tradeReference: string;
  references: Record<string, string>;
  error: string[];
  warning: string[];
}

export interface BookPreTradeDealRequest {
  underlying: string;
  way: TradeWay;
  portfolio: string;
  productQuantity: number;
  productPrice: number;
}
