import { useCallback, type PropsWithChildren } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { Button, type ButtonProps } from '@/components/common/bootstrap/Button.tsx';
import { useKey } from '@/components/common/hooks/useKey.ts';

interface ConfirmButtonProps extends ButtonProps {
  component?: JSX.Element | string;
}

interface CancelConfirmFooterProps {
  onConfirm?: () => void;
  confirmButtonProps?: ConfirmButtonProps;
  cancelButtonProps?: ButtonProps;
  removeOnConfirm?: boolean;
}

export function CancelConfirmFooter({
  onConfirm,
  confirmButtonProps,
  cancelButtonProps,
  children,
  removeOnConfirm = true,
}: PropsWithChildren<CancelConfirmFooterProps>): JSX.Element {
  const modal = useModal();

  const handleConfirm = useCallback(() => {
    onConfirm?.();
    if (removeOnConfirm) {
      modal.remove();
    }
  }, [modal, onConfirm, removeOnConfirm]);

  useKey(e => e.ctrlKey && e.key === 'Enter' && !confirmButtonProps?.disabled, handleConfirm);

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex align-self-end gap-2">
        <Button
          variant="secondary"
          size="lg"
          flat
          {...cancelButtonProps}
          onClick={() => modal.remove()}
        >
          Cancel
        </Button>
        <Button
          title="Ctrl+Enter"
          size="lg"
          variant="primary"
          {...confirmButtonProps}
          onClick={handleConfirm}
        >
          {confirmButtonProps?.component ?? 'Confirm'}
        </Button>
      </div>
      {children}
    </div>
  );
}
