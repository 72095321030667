export type Hierarchy = (typeof allHierarchies)[number];
export const allHierarchies = [
  'Region',
  'GRPPC1Desc',
  'GRPPC2Desc',
  'GRPPC3Desc',
  'ProfitCenterDesc',
  'ProfitCenter',
  'GRPGOPDesc',
  'GRPGOP',
  'GOP',
  'PortFolio',
  'UnderlyerType',
  'UnderlyerCode',
  'UnderlyerCurrency',
  'UnderlyerMarketPlace',
  'PayoffType',
  'ProductType',
  'ProductCode',
  'ProductMaturity',
  'DealId',
  'ClpId',
  'DefId',
  'ResetId',
  'RtPoseDealId',
  'PortfolioAxis1',
  'PortfolioAxis2',
  'PortfolioAxis3',
  'GOPAxis1',
  'GOPAxis2',
  'GOPAxis3',
  'DeskCustomAxis1',
  'DeskCustomAxis2',
  'DeskCustomAxis3',
  'DeskCustomAxis4',
  'DeskCustomAxis5',
  'SecurityIndustrySector',
  'SecurityIndustrySubSector',
  'SecurityIndustrySubSubSector',
  'SecurityIssueCountry',
  'SecurityIssuerCountryDom',
] as const;

//   Hierarchies for generating Data.cs
export const hierarchiesDataOnly = ['PricingDateTime'] as const;
export type HierarchyDataOnly = (typeof hierarchiesDataOnly)[number];
