import { useModal } from '@ebay/nice-modal-react';

import { useAppSelector } from '@/store/hooks.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { CreateAdjustmentModal } from '@/components/adjustments/CreateAdjustmentModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';

export function CreateAdjustmentModalButton() {
  const createAdjustmentModal = useModal(CreateAdjustmentModal);
  const portfolios = useAppSelector(queryCacheSlice.selectors.portfolios);

  async function openAdjustmentModal() {
    await createAdjustmentModal.show({
      portfolios,
    });
  }

  return (
    <Button onClick={openAdjustmentModal} className="btn-icon-end btn-lg text-nowrap">
      Add new <em className="icon">add</em>
    </Button>
  );
}
