import { createRoot } from 'react-dom/client';

import { fetchColorRules } from '@/store/slices/colorRules/colorsRulesSlice.ts';
import { fetchHedgerState } from '@/store/slices/prefs/hedgerSlice.ts';
import { fetchPerimeterPresets } from '@/store/slices/prefs/perimeterPresetsSlice.ts';
import {
  fetchUserPreferences,
  userPreferencesSlice,
} from '@/store/slices/prefs/userPreferencesSlice.ts';
import { querySlice } from '@/store/slices/query/querySlice.ts';
import { uiSlice, type CubeInstanceUI } from '@/store/slices/ui/uiSlice.ts';
import { fetchCurrentUser, userSlice } from '@/store/slices/user/userSlice.ts';
import { fetchWidgetState } from '@/store/slices/widget/widgetsSlice.ts';
import { store, type AppStore } from '@/store/store.ts';
import { getWebsocketUrl } from '@/web/greatApi/getWebsocketUrl.tsx';
import { App } from '@/components/App.tsx';
import { SELECTED_CUBE_SESSION_KEY } from '@/components/prefs/userPrefsStorage.ts';
import type { CubeMode, GreatServerConfig } from '@/types/AppConfig.ts';
import { getConfig } from '@/utils/config/config.ts';
import { fetchAdditionalConfig, isDevEnv } from '@/utils/config/configUtils.ts';
import { getFromStorage } from '@/utils/storage/storage.ts';
import { redirectToRequestAccessPage } from '@/utils/url.ts';
// FIXME: this needs to be imported last to avoid a dependency cycle
// prettier-ignore
import { fetchVisualizationPresets } from '@/store/slices/prefs/fetchVisualizationPresets.ts';

import type { ExclusionMode } from '@/store/slices/prefs/visualizationPresetSchema.ts';
import { getUrlParamAsString } from '@/utils/libs/searchParams.ts';

export async function renderApp() {
  await store.dispatch(fetchCurrentUser());
  const user = userSlice.selectors.maybeUser(store.getState());

  if (user === undefined) {
    redirectToRequestAccessPage();
    return;
  }

  await enhanceConfig();

  await Promise.all([
    store.dispatch(fetchUserPreferences()),
    store.dispatch(fetchPerimeterPresets()),
    store.dispatch(fetchVisualizationPresets()),
    store.dispatch(fetchColorRules()),
    store.dispatch(fetchHedgerState()),
    store.dispatch(fetchWidgetState()),
  ]);

  const userLocation = userPreferencesSlice.selectSlice(store.getState()).userLocation;
  const wsUrl = await getWebsocketUrl(user.role, userLocation);

  loadCubeInstances(store, wsUrl, isDevEnv() ? 'sgCube' : 'greatServer');
  const root = createRoot(document.getElementById('root')!);

  const exclusionRulesMode = getUrlParamAsString<ExclusionMode>('exclusionRulesMode');
  if (exclusionRulesMode !== undefined) {
    store.dispatch(querySlice.actions.setExclusionMode(exclusionRulesMode));
  }

  root.render(<App />);
}

function loadCubeInstances({ dispatch }: AppStore, wsUrl: string | undefined, cubeMode: CubeMode) {
  const config = getConfig();
  const availableGreatServers = config.availableGreatServers;
  const instances = availableGreatServers.map(toCubeInstanceUI);

  const selectedInstanceName =
    getFromStorage(SELECTED_CUBE_SESSION_KEY) ?? import.meta.env.VITE_CONFIG_GREAT_APPNAME;

  const selectedInstance = instances.find(
    instance => instance.cubeHostName === selectedInstanceName,
  );

  const fromLoadBalancerConfig: CubeInstanceUI = {
    cubeMode: selectedInstance?.cubeMode ?? cubeMode,
    cubeHostName: 'greatLB',
    webSocketUrl: wsUrl,
    trafficWatcher: selectedInstance?.trafficWatcher ?? getConfig().trafficWatcher,
  };

  instances.unshift(fromLoadBalancerConfig);
  dispatch(uiSlice.actions.setCubeInstances(instances));

  const activeInstance = selectedInstance ?? fromLoadBalancerConfig;

  dispatch(uiSlice.actions.setSelectedCubeInstanceName(activeInstance.cubeHostName));

  dispatch(
    querySlice.actions.setCubeInfos({
      cubeMode: activeInstance.cubeMode ?? 'greatServer',
      wsUrl: activeInstance.webSocketUrl,
    }),
  );
}

function toCubeInstanceUI(cubeInstance: GreatServerConfig): CubeInstanceUI {
  return {
    cubeMode: cubeInstance.mode ?? 'greatServer',
    webSocketUrl: cubeInstance.wsUrl,
    cubeHostName: cubeInstance.appName,
    trafficWatcher: cubeInstance.trafficWatcher,
  };
}

async function enhanceConfig(): Promise<void> {
  const isDevLocal = import.meta.env.VITE_CONFIG_ENVIRONMENT === 'local';
  const defaulfConfig = isDevLocal ? getConfig() : undefined;
  const additionalConfig = await fetchAdditionalConfig(defaulfConfig);

  if (additionalConfig !== undefined) {
    window.sgmeConfiguration = { ...window.sgmeConfiguration, ...additionalConfig };
  }
}
