import type { PropsWithChildren, ReactNode } from 'react';

export function ShowIf({
  children,
  condition,
  fallback = null,
}: PropsWithChildren<{ condition: boolean; fallback?: ReactNode }>): ReactNode | null {
  if (condition) {
    return children;
  }
  return <> {fallback} </>;
}
