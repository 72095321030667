import type {
  GetRowIdParams,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ServerSideTransaction,
} from '@ag-grid-community/core';

import type { RowData } from '@/core/parsing/parseResponse.ts';
import { getMdxForLevel } from '@/core/query/queryBuilder.ts';
import { makeQueryId } from '@/core/webSocket/queryId.ts';
import type { WebSocketConnection } from '@/core/webSocket/types.ts';
import {
  queryParameterSelector,
  queryParamsAreValid,
} from '@/store/selectors/QueryParameterSelector.ts';
import { userPreferencesSlice } from '@/store/slices/prefs/userPreferencesSlice.ts';
import { store as globalStore, type AppStore } from '@/store/store.ts';
import { getRowDataComparator } from '@/components/equityRisk/gridMappings/comparators.ts';
import { logger } from '@/utils/libs/logger.ts';

export function getRowId(params: GetRowIdParams<RowData>): string {
  const parentKeys = params.parentKeys ?? [];
  return parentKeys.concat(getGroupKey(params.data)).join('/');
}

export function getGroupKey(dataItem: RowData): string {
  return dataItem.dataPath.at(-1)!;
}

function getPathFromDataPath(dataPath: string[]): string {
  return dataPath.join('/');
}

export class WebSocketDataSource implements IServerSideDatasource {
  constructor(
    private readonly webSocket: WebSocketConnection,
    private readonly gridApi: GridApi,
    private readonly store: AppStore = globalStore,
    private readonly getQueryId = makeQueryId,
  ) {
    globalThis.__devOnlyDatasource = this;
    if (webSocket.isClosed()) {
      console.error(
        `Trying to create a data source with websocket ${webSocket.id}, which is closed`,
      );
    }
  }

  public cancelQueriesWithPath(dataPath: string[]) {
    const path = getPathFromDataPath(dataPath);
    this.webSocket.cancelQueries('table', queryId => queryId.startsWith(path));
  }

  public refreshAll() {
    this.webSocket.cancelQueries('table');
    this.gridApi.refreshServerSide({ route: [], purge: true });
  }

  public destroy(): void {
    this.webSocket.cancelQueries('table');
    this.webSocket.close();
  }

  public async getRows(params: IServerSideGetRowsParams<RowData>): Promise<void> {
    try {
      await this.doGetRows(params);
    } catch (e) {
      console.error(e);
      logger.logError('Failed to display rows: {message_s}', JSON.stringify(e));
      params.fail();
    }
  }

  private async doGetRows(params: IServerSideGetRowsParams<RowData>) {
    const appState = this.store.getState();
    const liveUpdates = appState.draftQuery.liveUpdates;
    const groupKeys = params.request.groupKeys;
    const path = groupKeys.join('/');

    this.cancelQueriesWithPath(groupKeys);

    function successEmpty() {
      params.success({
        rowData: [],
      });
    }

    let version = 0;
    if (!queryParamsAreValid(appState)) {
      successEmpty();
      version++;
      return;
    }

    const queryId = this.getQueryId(path);
    const queryParameters = queryParameterSelector(appState);
    const { filterKind } = appState.userPreferences;
    const isTotalQuery = groupKeys.length === 0;

    const contextValues = userPreferencesSlice.selectors.contextValues(appState);

    const responseIterator = this.webSocket.queryGenerator(
      {
        queryId,
        queryGroup: 'table',
        contextValues: {
          ...contextValues,
          priceType: queryParameters.priceType,
          exclusionRule: queryParameters.exclusionMode,
          'mdx.hiddengrandtotals': !isTotalQuery ? '1' : '0',
        },
        mdx: getMdxForLevel(groupKeys, queryParameters, filterKind),
        initialState: liveUpdates ? 'STARTED' : 'PAUSED',
      },
      {
        transform: rowData => (rowData.dataPath = groupKeys.concat(getGroupKey(rowData))),
      },
    );

    for await (const response of responseIterator) {
      if (++version === 1) {
        if (response.add.length === 0 && isTotalQuery) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }

        params.success({
          rowData: response.add.toSorted(
            getRowDataComparator(params.api, params.request.sortModel),
          ),
          rowCount: response.add.length,
        });
      } else {
        const transaction: ServerSideTransaction = {
          route: groupKeys,
          update: response.update,
          add: response.add,
          remove: response.remove,
        };
        this.gridApi.applyServerSideTransactionAsync(transaction);
      }
    }

    if (version === 0) {
      // If we never got result from the websocket,
      // We need to finish the getRows for the next one to be called
      successEmpty();
    }
  }
}
