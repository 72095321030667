import { setupSGWTConnect } from '@sgwt/connect-core';

import { getConfig } from '@/utils/config/config.ts';

const sgConnectConfig = getConfig().sgconnect;
// eslint-disable-next-line no-storage/no-browser-storage
const overrideClientId = sessionStorage.getItem('overrideClientId');

if (overrideClientId) {
  sgConnectConfig.client_id = overrideClientId;
}

console.log('sgwt connect', sgConnectConfig.client_id);
export const sgwtConnect = setupSGWTConnect(sgConnectConfig);

export function getAuthorizationHeader(): string | null {
  return sgwtConnect.getAuthorizationHeader();
}

export function getAuthorizationToken(): string | undefined {
  const authorizationHeader = getAuthorizationHeader();
  if (authorizationHeader == null) {
    return undefined;
  }
  return authorizationHeader.replace('Bearer ', '');
}
